.typewriter {
  font-family: 'Fira Code';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  overflow: hidden;
}

.cursor {
  display: inline-block;
  width: 2px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.75);
  animation: blink 1s step-start infinite;
  position: relative;
  top: 2px;
}

@keyframes blink {
  50% {
    background-color: transparent;
  }
}

@media (width < 576px) {
  .typewriter {
    font-size: 12px;
  }
}
