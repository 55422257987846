.kea-section-title {
  text-align: left;
  color:  #505050;
  font-family: 'Fira Code';
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.separator {
  margin: 32px 0px;
}

@media (width < 992px) {
  .kea-section-title {
    font-size: 20px;
  }
  .separator {
    margin: 16px 0px;
  }
}
