nav {
  border-bottom: 1px solid #C1FF72;
  .collapse {
    justify-content: space-between;
  }
  .nav-link, {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #C1FF72;
    cursor: pointer;
  }
  @media (width < 992px) {
    .container {
      padding-left: 16px !important;
    }
  }
}



.offcanvas {
  .offcanvas-header {
    min-height: 80px;
  }
  background: black;
  .offcanvas-body {

    display: flex;
    /* justify-content: flex-start; */
    flex-direction: column;
    justify-content: space-between;

    .link-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .nav-link {
      font-family: 'Inter';
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: left;
      color: #C1FF72;
      padding: 15px;
      cursor: pointer;
    }
  }
}