.contact {
  margin-bottom: 64px;
  &__container {
    flex-wrap: wrap;
    display: flex !important;
    align-content: center;
  }
  &__title {
    margin-right: 32px;
  }
  &__logo {
    height: 122px;
    width: 200px;
  }
  &__form {
    border-radius: 20px;
    border: 1px solid #353535;
    padding: 32px;
    width: 100%;
  }
  &__input-container {
    width: 100%;
    display: flex;
    padding:16px;
  }

  &__label {
    margin-right: 10px;
    color: #FFF;
    text-align: center;
    font-family: 'Fira Code';
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  input, textarea, {
    background: transparent;
    border: none;
    color: #ffffff;
    outline: none;
  }

  textarea {
    max-height: 30px;
    resize: none;
  }

  input:focus, textarea:focus, textarea:active, input:active {
    border: none;
    outline: none;
  }

  input:hover, textarea:hover {
    border: none;
  }

  &__button {
    border-radius: 16px;
    width: 150px;
    height: 64px;
  }
  &__button-container {
    margin-top: 32px;
    display: flex;
    justify-content: end;
  }

  &__form-success-title {
    color:  #FFF;
    font-family: 'Fira Code';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 16px 0px;
  }

    &__form-success-subtitle {
      color: #FFF;
      font-family: 'Fira Code';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
}

@media (width < 992px) {
    .contact {
      margin-bottom: 32px;
      &__title {
        margin-right: 10px;
      }
      &__logo {
        height: 61px;
        width: 100px;
      }
      &__button {
        width: 100%;
      }

      &__form-success-title {
        font-size: 20px;
      }

      &__form-success-subtitle {
        font-size: 14px;
      }
    }
}
