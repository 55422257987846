.App {
  background: #000000;

  .btn {
    border-radius: 16px;
  }

  .btn-primary {
    background-color: #C1FF72;
    border-color: #C1FF72;
    color: #000000;

  }

  .btn-primary:hover {
    background: #000;
    border-color: #C1FF72;
    color: #C1FF72;
  }

  .btn-outline-secondary {
    border-color: #A5D1AA;
    color: #A5D1AA;
    width: 150px;
    height: 48px;
  }
  .btn-outline-secondary:hover {
    background: #A5D1AA;
    border-color: #527E57;
    color: #527E57;
  }

  .btn.disabled, .btn:disabled, fieldset:disabled .btn {
    background-color: #D7D7D7;
    border-color: #D7D7D7;
    color: #505050;
  }

  .nav-link:hover {
    color: #C3C3C3;
  }
  .container-body {
    min-height: calc(100vh - 244px);
  }

  @media (width > 993px) {
    .btn-primary {
      min-width: 150px;
      min-height: 54px;
    }
   
  }

  @media (width < 992px) {

    .container-body {
      padding: 16px;
      min-height: calc(100vh - 216px);
    }
  }
}