.footer {
  padding: 30px;
  background: #282828;

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__social {
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
  }


  &__separator {
    width: 1px;
    height: 34px;
    background: #505050;
    margin: 0px 16px;
  }

  &__brand {
    color: #A5D1AA;
    text-align: center;
    font-family: 'Fira Code';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  img {
    cursor: pointer;
  }

}



@media (width < 992px) {
  .footer {
    padding: 16px 0px;
  }
  .footer__social {
    margin-bottom: 24px;
  }
}
