.presentation {
  margin-top: 32px;
  background-image: url("../assets/images/presentation-desktop.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 50vh;
}

.title {
  font-family: 'Inter';
  font-size: 48px;
  font-weight: 500;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: center;
  color: #C3C3C3;
  position: relative;
  top: 40px;
}

@media (1200px <= width <= 1400px) {
  .title {
    top: 60px;
  }

  .presentation {
    height: 650px;
  }
}

@media (991px <= width < 1199px) {
  .title {
    top: 40px;
    font-size: 36px;
  }

  .presentation {
    height: 500px;
  }
}

@media (width < 991px) {
  .presentation {
    background-size: contain;
  }
}

@media (769px <= width < 991px) {
  .title {
    top: 25px;
    font-size: 36px;
  }
  .presentation {
    height: 350px;
  }
}

@media (576px <= width < 769px) {
  .title {
    top: 10px;
    font-size: 28px;
  }

  .presentation {
    height: 300px;
  }

}

@media (width < 576px) {
  .title {
    top: 0px;
    font-size: 20px;
  }

  .presentation {
    height: 250px;
  }
}
