

.about-us {
  &__title {
    margin-top: 32px;
    text-align: right;
    color:  #505050;
    font-family: 'Fira Code';
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  &__card {
    display: flex;
    padding: 32px 0px;
    justify-content: space-between;
    align-items: center;
  }
  &__text {
    font-family: 'Fira Code';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__text--color-1 {
    color: #C3C3C3;
  }
  &__text--color-2 {
    color: #C1FF72;
  }

  &__img-container {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__img {
    border-radius: 200px;
    background: var(--Background-Dark-Quarternary, #505050);
    width: 250px;
    height: 250px;
  }
  &__img-left {
    margin-right: 65px;
  }

  &__img-flipped {
    opacity: 0.5;
  }

  &__img-1::before {
    background-image: url("../assets/images/about-us-img-2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.5;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 200px;
  }

  &__img-2::before {
    background-image: url("../assets/images/about-us-img-1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.5;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 200px;
  }

  &__profile-text {
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;
    z-index: 10;
    text-align: center;
  }
  &__profile--white {
    color: #FFFFFF;
  }
  &__profile--green {
    color: #C1FF72;
  }
  &__profile--linkedin {
    cursor: pointer;
  }
  &__profile--profession {
    margin: 16px 0px;
  }
}


@media (width < 992px) {
  .about-us {
    &__title {
      font-size: 20px;
    }

    &__card {
      padding: 16px 0px;
      flex-direction: column;
      align-items: center;

    }

    &__text {
      text-align: center;
    }

    &__second-section {
      display: flex;
      flex-direction: column;
    }

    &__img-left {
      margin-top: 32px;
      margin-right: 0px;
      margin-bottom: 32px;

    }

    &__img-container, &__img {
      width: 200px;
      height: 200px;
    }
    &__profile-text {
      font-size: 14px;
    }
  }
}



