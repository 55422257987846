.project-title {
  margin-top: 32px;
  text-align: center;
  color:  #505050;
  font-family: 'Fira Code';
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.card-container {
  display: flex;
  padding: 32px 64px;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
  border-radius: 20px;
  background: var(--Background-Dark-Secondary, #282828);
}

.second-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card-title {
  color:  #A5D1AA;
  font-family: "Fira Code";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
}

.card-subtitle {
  color: #527E57;
  font-family: "Fira Code";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.card-action {
  display: flex;
  align-items: center;
}

@media (width < 992px) {
  .card-container {
    padding: 32px 16px;
    text-align: center;
    flex-direction: column;
  }
  .second-section {
    flex-direction: column;
  }
  .card-action {
    margin-top: 32px;
    width: 100%;
    justify-content: center;
    button {
      width: 100%;
    }
  }
  .card-title {
    font-size: 14px;
  }
  .card-subtitle {
      font-size: 14px;
  }

  .logo {
    img {
      width: 70px;
      height: 45px;
    }
  }

  .project-title {
    font-size: 20px;
  }
}
